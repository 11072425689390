import validate from "/Users/david/Desktop/project/jwj_vue/node_modules/nuxt/dist/pages/runtime/validate.js";
import route_45global from "/Users/david/Desktop/project/jwj_vue/middleware/route.global.js";
import manifest_45route_45rule from "/Users/david/Desktop/project/jwj_vue/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  route_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  signin: () => import("/Users/david/Desktop/project/jwj_vue/middleware/signin.js")
}