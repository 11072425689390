import { getCodeListAPI, getNoticeListAPI, getTodayNoticeAPI, getMemInfoAPI, getDutyCodeListAPI } from "~/store/api/common";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";

export const useCommonStore = defineStore("common", () => {
  const { t } = useI18n();

  const pageKey = reactive({
    today: 111111,
    write: 222222,
    app: 333333,
  });

  function setPageKey(page) {
    const randomSixDigit = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
    pageKey[page] = randomSixDigit;
  }

  const menuCdList = reactive({
    투데이: "dwj.menu.usr.001",
    쓰기: "dwj.menu.usr.002",
    일기장: "dwj.new.msg.30401",
    나눔방: "next.room",
    더보기: "dwj.com.btn.018",
    todayWithStory: "todayWithStory.title",
    todayColumn: "next.today.pastor.you",
    todayRcmd: "next.rcmd",
    todayMoment: "todayMoment.title",
    today365: "today365.title",
    todayRoom: "todayRoom.title",
    todayGroupPosting: "todayGroupPosting.title",
  });

  function cdMatcher(cd) {
    return menuCdList[cd] || cd;
  }
  const refreshPage = reactive({
    today: false,
    write: false,
  });

  async function refreshPageActor(page) {
    refreshPage[page] = true;
    setTimeout(() => {
      refreshPage[page] = false;
    }, 0);
  }

  const todayNoticePop = reactive({
    preventShow: false,
    open: false,
    pop: {},
  });

  const appHeader = reactive({
    pageTitle: "",
    btnBack: false,
    btnClose: false,
    pageAuthor: false,
    author: {
      name: "",
      imgSrc: "",
      emotion: "",
      likeItCount: "",
    },
    bottomNav: true,
  });
  let confirmDialog = reactive({
    open: false,
    title: "",
    text: "",
    positive: { name: "", actor: null },
    negative: { name: "", actor: null },
  });
  let userLangCode = ref("");
  const langMatchList = {
    한국어: "ko",
    English: "en",
    "中文(繁体)": "tw",
    "中文(简体)": "cn",
    Indonésia: "id",
  };
  let languageList = reactive([
    { value: "ko", text: "한국어", commonCd: "", i18nCode: "" },
    { value: "en", text: "English", commonCd: "", i18nCode: "" },
    { value: "cn", text: "中文(简体)", commonCd: "", i18nCode: "" },
    { value: "tw", text: "中文(繁体)", commonCd: "", i18nCode: "" },
    { value: "id", text: "Indonésia", commonCd: "", i18nCode: "" },
  ]);

  async function getCodeList() {
    try {
      const { statusText, data: res = {} } = await getCodeListAPI();
      if (statusText !== "OK" || res?.codeList === undefined) throw Error;
      languageList = [
        ...res.codeList
          .reduce((acc, cur) => {
            //acc.push({ value: langMatchList[cur.commonCdName], text: t(cur.langMssgCd), commonCd: cur.commonCd, i18nCode: cur.langMssgCd });
            acc.push({ value: langMatchList[cur.commonCdName], text: cur.commonCdName, commonCd: cur.commonCd, i18nCode: cur.langMssgCd });
            return acc;
          }, [])
          .sort((a, b) => a.sortNum - b.sortNum),
      ];
      console.log("languageList : ", languageList);
      return languageList;
    } catch (err) {
      console.error(`getCodeList err : `, err);
    }
  }

  function setPageTitle(title) {
    appHeader.pageTitle = title;
  }

  function setBtnBack(boolean) {
    appHeader.btnBack = boolean;
  }
  function setBtnClose(boolean) {
    appHeader.btnClose = boolean;
  }
  function showBottomNav(boolean) {
    appHeader.bottomNav = boolean;
  }

  async function setUserLangCode(code) {
    userLangCode = code;
    return userLangCode;
  }

  function transDiffTime(value) {
    try {
      //
      const regex = /[^0-9]/g; // 숫자가 아닌 문자만
      const regexNum = /\d+/g;

      //const regexString = /([가-힣]+)|(?:[\u3400-\u4DBF\u4E00-\u9FFF]+)|(?:[a-zA-Z]+)|(?:[\u270c-\u270d\u1b80-\u1bbf\u1cc0-\u1cc7]+)/g;

      const string = value.replace(regexNum, "");
      const num = value.replace(regex, "");
      /*
       */

      const matcher = {
        "초 전": `dwj.new.msg.004`,
        "분 전": "dwj.new.msg.005",
        "한 시간 전": "dwj.new.msg.006",
        "시간 전": "dwj.new.msg.006",
        "일 전": "dwj.new.msg.007",
        "하루 전": "dwj.new.msg.007",
        "한 달 전": "dwj.new.msg.007",
      };
      /*
       */
      if (isEmpty(num) && string === "하루 전") return `1${t("dwj.new.msg.007")}`;
      else if (isEmpty(num) && string === "한 달 전") return `30${t("dwj.new.msg.007")}`;
      else return `${num}${t(matcher[string])}`;
    } catch (err) {
      console.error(`transDiffTime err : `, err.message);
    }
  }

  async function setConfirmDialog(obj) {
    // confirmDialog = { ...obj };
    Object.keys(obj).forEach((itm) => {
      confirmDialog[itm] = obj[itm];
    });
    return confirmDialog;
  }

  async function getNoticeListActor(payload) {
    try {
      const { statusText, data: res = {} } = await getNoticeListAPI(payload);

      return res;
    } catch (err) {
      console.error(`getNoticeListActor err : `, err.message);
    }
  }

  function setPreventNoticePop() {
    // 현재부터 24시간 후의 시간을 계산
    const twentyFourHoursLater = dayjs().add(24, "hour").format();

    // todayNoticePop.preventShowEnd를 이 시간으로 업데이트
    localStorage.setItem("preventShowEnd", twentyFourHoursLater);
    // 팝업이 보이지 않도록 preventShow를 true로 설정
    todayNoticePop.preventShow = true;
    todayNoticePop.open = false;
  }

  async function getTodayNoticeActor() {
    try {
      const { data: res = {} } = await getTodayNoticeAPI();
      //console.log("getTodayNoticeActor res : ", res);
      // 현재 시간과 preventShowEnd 시간을 한 번만 계산하여 비교
      const now = dayjs();
      const preventShowEnd = localStorage.getItem("preventShowEnd");
      if (preventShowEnd) {
        const isPreventShowEndTimePassed = now.isAfter(dayjs(preventShowEnd));
        //console.log("isPreventShowEndTimePassed : ", isPreventShowEndTimePassed);

        // preventShowEnd 시간을 지났거나 preventShowEnd가 설정되지 않은 경우 초기화
        if (isPreventShowEndTimePassed) {
          localStorage.removeItem("preventShowEnd");
          todayNoticePop.preventShow = false;

          if (res?.result?.length > 0) {
            todayNoticePop.pop = res.result[0];
            todayNoticePop.open = true;
          }
        } else {
          todayNoticePop.open = false;
        }
      } else {
        if (res?.result?.length > 0) {
          todayNoticePop.pop = res.result[0];
          todayNoticePop.open = true;
        } else {
          todayNoticePop.open = false;
        }
      }

      return res;
    } catch (err) {
      console.error(`getTodayNoticeActor err : `, err.message);
    }
  }

  /**
   *
   * @param {string} memCd
   * @returns
   */
  async function getMemInfoActor(payload) {
    return await getMemInfoAPI(payload);
  }

  async function getDutyCodeListActor() {
    try {
      const { data: res = {} } = await getDutyCodeListAPI();
      console.log("getDutyCodeListActor res : ", res);
      return res;
    } catch (err) {
      console.error(`getDutyCodeListActor err : `, err);
    }
  }

  return {
    todayNoticePop,
    confirmDialog,
    appHeader,
    userLangCode,
    languageList,
    pageKey,
    refreshPage,
    getCodeList,
    setPageTitle,
    setBtnBack,
    setBtnClose,
    showBottomNav,
    setUserLangCode,
    transDiffTime,
    setConfirmDialog,
    getNoticeListActor,
    getTodayNoticeActor,
    setPreventNoticePop,
    refreshPageActor,
    setPageKey,
    getMemInfoActor,
    cdMatcher,
    getDutyCodeListActor,
  };
});
