import revive_payload_client_4sVQNw7RlN from "/Users/david/Desktop/project/jwj_vue/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/david/Desktop/project/jwj_vue/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/david/Desktop/project/jwj_vue/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/david/Desktop/project/jwj_vue/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/david/Desktop/project/jwj_vue/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/david/Desktop/project/jwj_vue/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/david/Desktop/project/jwj_vue/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/david/Desktop/project/jwj_vue/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/david/Desktop/project/jwj_vue/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/david/Desktop/project/jwj_vue/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ghbUAjaD3n from "/Users/david/Desktop/project/jwj_vue/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_dac0So5JJ9 from "/Users/david/Desktop/project/jwj_vue/node_modules/@kevinmarrec/nuxt-pwa/dist/runtime/workbox/plugin.mjs";
import customDirectives_mPCDmOIJcJ from "/Users/david/Desktop/project/jwj_vue/plugins/customDirectives.js";
import dayjs_pGiXRjcsJO from "/Users/david/Desktop/project/jwj_vue/plugins/dayjs.ts";
import i18n_VfGcjrvSkj from "/Users/david/Desktop/project/jwj_vue/plugins/i18n.ts";
import maska_UHaKf2z1iQ from "/Users/david/Desktop/project/jwj_vue/plugins/maska.ts";
import middleware_2wzLuOatkW from "/Users/david/Desktop/project/jwj_vue/plugins/middleware.ts";
import naviGuard_6yMoz7zdxQ from "/Users/david/Desktop/project/jwj_vue/plugins/naviGuard.ts";
import origin_client_n6KlJDYOVR from "/Users/david/Desktop/project/jwj_vue/plugins/origin.client.ts";
import v_viewer_i6hqZN1mgF from "/Users/david/Desktop/project/jwj_vue/plugins/v-viewer.ts";
import vuetify_7h9QAQEssH from "/Users/david/Desktop/project/jwj_vue/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ghbUAjaD3n,
  plugin_dac0So5JJ9,
  customDirectives_mPCDmOIJcJ,
  dayjs_pGiXRjcsJO,
  i18n_VfGcjrvSkj,
  maska_UHaKf2z1iQ,
  middleware_2wzLuOatkW,
  naviGuard_6yMoz7zdxQ,
  origin_client_n6KlJDYOVR,
  v_viewer_i6hqZN1mgF,
  vuetify_7h9QAQEssH
]