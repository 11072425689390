export default {
  blank: () => import("/Users/david/Desktop/project/jwj_vue/layouts/blank.vue"),
  default: () => import("/Users/david/Desktop/project/jwj_vue/layouts/default.vue"),
  "default-backup": () => import("/Users/david/Desktop/project/jwj_vue/layouts/defaultBackup.vue"),
  diary: () => import("/Users/david/Desktop/project/jwj_vue/layouts/diary.vue"),
  "more-20240921": () => import("/Users/david/Desktop/project/jwj_vue/layouts/more.20240921.vue"),
  "more-bak-20240310": () => import("/Users/david/Desktop/project/jwj_vue/layouts/more.bak.20240310.vue"),
  "more-bak-20240713": () => import("/Users/david/Desktop/project/jwj_vue/layouts/more.bak.20240713.vue"),
  "more-bak-20240911": () => import("/Users/david/Desktop/project/jwj_vue/layouts/more.bak.20240911.vue"),
  "more-bak-20240921": () => import("/Users/david/Desktop/project/jwj_vue/layouts/more.bak.20240921.vue"),
  "more-bak": () => import("/Users/david/Desktop/project/jwj_vue/layouts/more.bak.vue"),
  more: () => import("/Users/david/Desktop/project/jwj_vue/layouts/more.vue"),
  room: () => import("/Users/david/Desktop/project/jwj_vue/layouts/room.vue"),
  "today-section": () => import("/Users/david/Desktop/project/jwj_vue/layouts/todaySection.vue"),
  write: () => import("/Users/david/Desktop/project/jwj_vue/layouts/write.vue")
}